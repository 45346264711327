<template>
    <v-row no-gutters justify="center" align-content="center" class="pa-5" style="height: 100%;">
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <template v-if="!isViewReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <!-- NOTE: we use this app-splash-loader instead of v-progress-circular because we want this to look exactly
                    like the index.html splash loader so the visual transition is smooth for the user as the rest of the UI loads -->
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="isClientError">
                <v-alert type="error">
                    We cannot process this request.
                </v-alert>
                <p class="text-h5 font-weight-light mt-6">The request was either not found, expired, or may have been created incorrectly.</p>
                <p class="text-body-1 font-weight-light text-center"><router-link :to="{ name: 'front' }">Continue</router-link></p>
            </template>
            <template v-if="errorUnauthorizedInteraction">
                <v-alert type="error">
                    Unauthorized
                </v-alert>
                <!-- <p class="text-h5 font-weight-light red--text">Unauthorized</p> -->
                <p class="text-h5 font-weight-light mt-6">Did you start in another browser? Try opening the link in that browser, or start over in this one.</p>
                <p class="text-body-1 font-weight-light text-center"><router-link :to="{ name: 'front' }">Start over</router-link></p>
            </template>
            <template v-if="isServerError">
                <v-alert type="error">
                    We cannot process this request due to a temporary server error. Reload the page to try again.
                    If it&apos;s not resolved in a few minutes, please <a :href="customerSupportURL" target="_blank">contact customer support</a>.
                </v-alert>
            </template>
        </v-col>
    </v-row>
</template>
<style lang="css">
.app-splash-loader {
    border: 4px solid #BBDEFB; /* blue lighten-4 */
    border-top: 4px solid #2196F3; /* blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.0s linear infinite;
    margin: auto;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
<script>
import { mapState /* , mapGetters */ } from 'vuex';
import { ROUTE_LOGIN, ROUTE_SIGNUP, ROUTE_PROMPT, ROUTE_USER_PROFILE, INTENT_ATTEST, INTENT_LOGIN, INTENT_SIGNUP, INTENT_USER_PROFILE, IA_LOGIN, IA_SIGNUP, IA_PROFILE_RESPONSE, IA_VERIFY_EMAIL, INTENT_PROFILE_RESPONSE } from '@/sdk/constants';
// import { client } from '@/client';

/*
Overview:
An interaction record stores a kind of process state. Any process that requires the user to leave
the site and return, such as email verification with a link, can store its state in an interaction
and send the redirect URL (with a token embedded in the query parameters) to the user in some way
(e.g. email) and when the user returns with the token we validate it and then redirect the user back
to what they were doing using the stored state. It's also possible for a multi-step process to use
an interaction record without a token to simply store the current state of the process as a kind of
mini-session so that 1) we're not relying on untrusted query parameters to carry state between activities
and 2) so that we don't lose the state if the user reloads the page because as long as the query includes
the interaction id, we can look up the current state, and 3) so that the user can have multiple instances
of some kinds of workflows in progress without conflicting with each other, and 4) to remove clutter
from the session record and implement data retention policies on that data, and 5) to be able to
transfer part or all of the session data from one session to another, such as when a user wants to
continue on another browser or device.

User arrives here with a token or interaction id in the query parameters.

If user arives with a token, we send the token to the server and the response is one of these:
1. OK (with an object describing what to do next, typically an interaction id or intent with parameters)
2. Forbidden (token is invalid, not found, or expired)

If user arrives with an interaction id, we load the interaction and the server response is one of these:
1. OK (with interaction state, which we use to redirect user to the next step)
2. Forbidden (interaction is invalid, not found, expired, or not authorized for this session)

NOTE: To avoid coupling of the UI named routes, path parameters, and query parameters with the server code,
the server will reply with an "intent" and we will translate that to a route and determine which parameters
are path parameters and which are query parameters. The number of parameters passed this way should be
kept to a minimum. The target activity should then load additional data as needed from the server. The
`intent` query parameter and other query parameters such as `email` that are checked here are merely a backup,
so if the token doesn't work we might still be able to redirect the user somewhere useful to start over.
However, if the token exchange is successful, such redundant parameters will be ignored.

Intent parameters:
Email - login and signup interactions may include an email address that we need to forward to next step
Product - signup interaction may include a product lookup key that we need to forward to next step
*/

export default {
    components: {
    },
    data: () => ({
        isViewReady: false,
        // interactionId: null,
        interactionError: false,
        // intent: null, // login, signup, etc.
        // intentParams: null,
        errorBadRequest: false,
        errorForbidden: false,
        errorUnauthorizedInteraction: false,
        serverError: false,
        nextRoute: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isClientError() {
            return this.interactionError || this.errorBadRequest || this.errorForbidden;
        },
        isServerError() {
            return this.serverError;
        },
        isError() {
            return this.isClientError || this.isServerError || this.errorUnauthorizedInteraction;
        },
        customerSupportURL() {
            return process.env.VUE_APP_SUPPORT_URL ?? process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://cryptium.com';
        },
    },
    methods: {
        async init() {
            // TODO: redo as cryptium id profile token, and maybe keep a separate one for etherlink.
            // if (this.$route.query.etherlink_profile_token) {
            //     await this.verifyEmail(this.$route.query.etherlink_profile_token);
            // }
            if (this.$route.query.token) {
                await this.resumeInteraction(this.$route.query.token);
                // if the token was exchanged for permission to access the interaction, we
                // can stop processing here and redirect the user to the next route for the
                // interaction.
                if (this.nextRoute) {
                    console.log(`after resumeInteraction with nextRoute: ${JSON.stringify(this.nextRoute)}`);
                    this.$router.replace(this.nextRoute);
                    return;
                }
                /*
                if (this.interactionId) {
                    // remove the token from the URL so if the user navigates back here we won't
                    // attempt to exchange the same token twice, we'll just load the interaction
                    // data in the next step instead.
                    const query = {
                        ...this.$route.query,
                        i: this.interactionId,
                        t: Date.now(),
                    };
                    delete query.token;
                    console.log(`after resumeInteraction with query: ${JSON.stringify(query)}`);
                    this.$router.replace({ name: ROUTE_INTERACTION, query });
                }
                */
            }
            // if user arrived with an interaction id, try to load the interaction; if successful,
            // the user will be redirected to the next route according to the interaction and we
            // will ignore any other query parameters
            if (this.$route.query.i) {
                await this.loadInteraction(this.$route.query.i);
                if (this.nextRoute) {
                    console.log(`after loadInteraction with nextRoute: ${JSON.stringify(this.nextRoute)}`);
                    this.$router.replace(this.nextRoute);
                    return;
                }
            }
            // if user arrived with an intent and possibly other parameters in the query, try to
            // redirect the user to the appropriate route
            if (this.$route.query.intent) {
                this.resumeIntent(this.$route.query.intent);
                if (this.nextRoute) {
                    console.log(`after resumeIntent with nextRoute: ${JSON.stringify(this.nextRoute)}`);
                    this.$router.replace(this.nextRoute);
                    return;
                }
            }
            this.isViewReady = true;
        },
        handleError() {
            if (this.$route.query.intent) {
                // redirect user according to the backup intent and parameters in the query;
                // NOTE: an attacker does not gain anything with this, because they could have redirected the user to the destination URL themselves, and it's only on our site so everyone knows we ignore the browser's 'referer' header
                this.resumeIntent(this.$route.query.intent);
                return;
            }
            this.errorBadRequest = true;
        },
        async resumeInteraction(token) {
            try {
                this.$store.commit('loading', { resumeInteraction: true });
                const interaction = await this.$client.main().interaction.resume(token);
                console.log(`resumeInteraction response: ${JSON.stringify(interaction)}`);
                const { id, type, state, error } = interaction;
                // response will have either interactionId OR intent and params OR error, but not more than one of these
                if (error) {
                    this.interactionError = true;
                    return;
                }
                if (id && type && state) {
                    this.setNextRouteFromInteraction({ id, type, state });
                    return;
                }
                this.handleError();
            } catch (err) {
                console.error('failed to activate token', err);
                this.serverError = true;
            } finally {
                this.$store.commit('loading', { resumeInteraction: false });
            }
        },
        async loadInteraction(interactionId) {
            const interaction = await this.$store.dispatch('loadInteraction', interactionId);
            if (interaction) {
                console.log(`InteractionForward.vue: loadInteraction: interaction ${JSON.stringify(interaction)}`);
                this.setNextRouteFromInteraction(interaction);
            } else {
                this.errorUnauthorizedInteraction = true;
            }
        },
        // TODO: verify email should be a single interaction type, and the INTENT should be login, setup, profile, prompt, etc.
        setNextRouteFromInteraction({ id, type, state }) {
            const query = {
                ...this.$route.query,
                i: id,
                t: Date.now(),
            };
            delete query.token; // in case the user arrived here with an interaction token in the query parameters, we don't want to forward it to next route
            switch (type) {
            case IA_LOGIN: // TODO: remove this case and use the intnent below for ia verify email
                ['email', 'verify_email'].forEach((item) => {
                    query[item] = state[item];
                });
                this.nextRoute = { name: ROUTE_LOGIN, query };
                // this.intent = INTENT_LOGIN;
                // this.intentParams = state; // email, verified_email
                break;
            case IA_SIGNUP: // TODO: remove this case and use the intnent below for ia verify email
                ['email', 'verify_email', 'product'].forEach((item) => {
                    query[item] = state[item];
                });
                this.nextRoute = { name: ROUTE_SIGNUP, query };
                // this.intent = INTENT_SIGNUP;
                // this.intentParams = state; // email, verified_email, product
                break;
            case IA_PROFILE_RESPONSE: // TODO: remove this case and use the intnent below for ia verify email
                ['email'].forEach((item) => {
                    query[item] = state[item];
                });
                this.nextRoute = { name: ROUTE_PROMPT, query };
                break;
            case IA_VERIFY_EMAIL: {
                const { intent /* , intent_params: intentParams */ } = state;
                switch (intent) {
                case INTENT_LOGIN:
                    ['email', 'verify_email'].forEach((item) => {
                        query[item] = state[item];
                    });
                    this.nextRoute = { name: ROUTE_LOGIN, query };
                    break;
                case INTENT_SIGNUP:
                    ['email', 'verify_email', 'product'].forEach((item) => {
                        query[item] = state[item];
                    });
                    this.nextRoute = { name: ROUTE_SIGNUP, query };
                    break;
                case INTENT_ATTEST:
                    ['email'].forEach((item) => {
                        query[item] = state[item];
                    });
                    this.nextRoute = { name: ROUTE_PROMPT, query };
                    break;
                case INTENT_USER_PROFILE:
                    ['email'].forEach((item) => {
                        query[item] = state[item];
                    });
                    this.nextRoute = { name: ROUTE_USER_PROFILE, query };
                    break;
                default:
                    this.nextRoute = null;
                    // this.intent = null;
                    // this.intentParams = null;
                    this.serverError = true;
                    break;
                }
                break;
            }
            default:
                this.nextRoute = null;
                // this.intent = null;
                // this.intentParams = null;
                this.serverError = true;
                break;
            }
        },
        /*
        setIntentFromInteraction(interaction = {}) {
            const { type, state } = interaction;
            switch (type) {
            case IA_LOGIN:
                this.intent = INTENT_LOGIN;
                this.intentParams = state; // email, verified_email
                break;
            case IA_SIGNUP:
                this.intent = INTENT_SIGNUP;
                this.intentParams = state; // email, verified_email, product
                break;
            default:
                this.intent = null;
                this.intentParams = null;
                this.serverError = true;
                break;
            }
        },
        */
        resumeIntent(intent) {
            const query = {
                ...this.$route.query,
                t: Date.now(),
            };
            delete query.token; // in case the user arrived here with an interaction token in the query parameters, we don't want to forward it to next route

            switch (intent) {
            case INTENT_LOGIN:
                // ['email'].forEach((item) => {
                //     query[item] = state[item];
                // });
                this.nextRoute = { name: ROUTE_LOGIN, query };
                break;
            case INTENT_SIGNUP:
                // ['email', 'product'].forEach((item) => {
                //     query[item] = state[item];
                // });
                this.nextRoute = { name: ROUTE_SIGNUP, query };
                break;
            case INTENT_PROFILE_RESPONSE:
                this.nextRoute = { name: ROUTE_PROMPT, query }; // TODO: not email from query, we need email from server resume-interaction response
                break;
            default:
                console.log(`resumeIntent: unsupported intent: ${JSON.stringify(intent)}`);
                this.errorBadRequest = true;
                break;
            }
            console.log(`resumeIntent with query: ${JSON.stringify(query)}`);
            // this.$router.replace({ name: ROUTE_INTENT, query });
        },
    },
    mounted() {
        this.init();
    },
};
</script>
